var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view",
      "hideTopDivider": ""
    }
  }, 'vertical-form-table', {
    value: _vm.value,
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }